import { inject }                              from 'aurelia-framework';
import { PLATFORM }                            from 'aurelia-pal';
import { activationStrategy }                  from 'aurelia-router';
import { BaseFormViewModel }                   from 'base-form-view-model';
import { FormSchema }                          from 'modules/management/concessions/concessions-tree/lot-interventions/form-schema';
import { LotInterventionSubsectionFormSchema } from 'modules/management/concessions/concessions-tree/lot-interventions/lot-interventions-subsections/form-schema';
import { LotInterventionsRepository }          from 'modules/management/concessions/concessions-tree/lot-interventions/services/repository';
import { AppContainer }                        from 'resources/services/app-container';

@inject(AppContainer, LotInterventionsRepository, FormSchema, LotInterventionSubsectionFormSchema)
export class EditLotIntervention extends BaseFormViewModel {

    headerTitle = 'form.title.edit-record';
    formId      = 'management.concessions.lot-interventions.edition-form';

    alert  = {};
    model  = {};
    schema = {};

    /**
     * Constructor
     *
     * @param appContainer
     * @param repository
     * @param formSchema
     * @param lotInterventionSubsectionFormSchema
     */
    constructor(appContainer, repository, formSchema, lotInterventionSubsectionFormSchema) {
        super(appContainer);

        this.repository                          = repository;
        this.formSchema                          = formSchema;
        this.lotInterventionSubsectionFormSchema = lotInterventionSubsectionFormSchema;
    }

    /**
     * Handles canActivate event
     */
    canActivate() {
        return super.can([
            'management.concessions.concessions.lot-interventions.manage',
            'management.concessions.concessions.lot-interventions.edit',
        ]);
    }

    /**
     * Returns view strategy
     *
     * @returns {string}
     */
    getViewStrategy() {
        return PLATFORM.moduleName('modules/management/concessions/concessions-tree/lot-interventions/form.html');
    }

    /**
     * Determines activation strategy
     *
     * @return {'replace'}
     */
    determineActivationStrategy() {
        return activationStrategy.replace;
    }

    /**
     * Handles activate event
     *
     * @param params
     *
     * @returns {Promise.<*>|Promise}
     */
    async activate(params) {
        super.activate();

        this.initialModel = this.formSchema.model();
        this.model        = this.formSchema.model();

        return this.fetchData(params).then(() => {
            this.schema           = this.formSchema.schema(this);
            this.subsectionSchema = this.lotInterventionSubsectionFormSchema.schema(this);
            this.globalSchema     = this.formSchema.globalSchema(this);
        });
    }

    /**
     * Submits view form
     *
     * @returns {Promise}
     */
    submit() {
        this.alert = null;

        this.repository
            .update(this.model.id, this.model)
            .then((response) => {
                this.handleResponse(response, 'management.concessions.concessions.index');
                this.initialModel.assign(this.model);
            })
            .catch((error) => console.error(error));
    }

    /**
     * Fetches data from server
     *
     * @param params
     *
     * @returns {Promise.<*>|Promise}
     */
    async fetchData(params) {
        const response = await this.repository.find(params.id);
        this.initialModel.assign(response);
        this.model.assign(response);
    }

    /**
     * Closes or reopens lot
     *
     * @param reopen
     * @return {*}
     */
    closeOrReopenLot(reopen) {
        const action = reopen ? this.repository.reopen.bind(this.repository) : this.repository.close.bind(this.repository);

        return action(this.model.id)
            .then(response => {
                if (response.status) {
                    this.handleResponse(response, 'management.concessions.concessions.index');
                }
            })
            .catch(error => console.error(error));
    }
}
