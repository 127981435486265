import { DialogService }                  from 'aurelia-dialog';
import { inject }                         from 'aurelia-framework';
import { I18N }                           from 'aurelia-i18n';
import { BooleanOption }                  from 'modules/administration/models/boolean-option';
import { GeosigPhasesRepository }         from 'modules/management/concessions/concessions-tree/geosig-phases/services/repository';
import { InterventionTypesRepository }    from 'modules/management/concessions/concessions-tree/intervention-types/services/repository';
import { LotInterventionTypesRepository } from 'modules/management/concessions/concessions-tree/lot-intervention-types/services/repository';
import { LotIntervention }                from 'modules/management/models/lot-intervention';
import { LotInterventionType }            from 'modules/management/models/lot-intervention-type';
import { ConfirmCloseLotDialog }          from 'resources/elements/html-elements/dialogs/confirm-close-lot-dialog';
import { ConfirmReopenLotDialog }         from 'resources/elements/html-elements/dialogs/confirm-reopen-lot-dialog';

@inject(I18N, DialogService, GeosigPhasesRepository, LotInterventionTypesRepository, InterventionTypesRepository)
export class FormSchema {

    /**
     * Model default values
     */
    modelDefaults = {
        //
    };

    /**
     * Constructor
     *
     * @param i18N
     * @param dialogService
     * @param geosigPhasesRepository
     * @param lotInterventionTypesRepository
     * @param interventionTypesRepository
     */
    constructor(i18N, dialogService, geosigPhasesRepository, lotInterventionTypesRepository, interventionTypesRepository) {
        this.i18N                           = i18N;
        this.dialogService                  = dialogService;
        this.geosigPhasesRepository         = geosigPhasesRepository;
        this.lotInterventionTypesRepository = lotInterventionTypesRepository;
        this.interventionTypesRepository    = interventionTypesRepository
    }

    /**
     * Returns a new instance of the model
     *
     * @returns {LotIntervention}
     */
    model() {
        let model = new LotIntervention();

        model.assign(this.modelDefaults);

        return model;
    }

    /**
     * Returns form schema
     *
     * @param viewModel
     *
     * @param readonly
     * @returns {*[]}
     */
    schema(viewModel, readonly = false) {
        this.lot_intervention_type_id = {
            type:         'select2',
            key:          'lot_intervention_type_id',
            label:        'form.field.type',
            size:         6,
            required:     false,
            remoteSource: () => this.lotInterventionTypesRepository.all(),
            attributes:   {
                disabled: readonly,
            },
            observers:    [
                async (newValue) => {
                    await this.handleLotInterventionTypeChange(newValue, viewModel)
                },
            ],
        };

        this.number = {
            type:       'text',
            key:        'number',
            label:      'form.field.number',
            size:       6,
            attributes: {
                maxlength: 255,
                readonly:  readonly,
            },
        };

        this.code = {
            type:       'text',
            key:        'code',
            label:      'form.field.code',
            size:       6,
            required:   false,
            attributes: {
                readonly: readonly,
            },
        };

        this.geosig_phase_id = {
            type:         'select2',
            key:          'geosig_phase_id',
            label:        'form.field.geosig-phase',
            size:         6,
            required:     false,
            remoteSource: () => this.geosigPhasesRepository.all(),
            attributes:   {
                disabled: readonly,
            },
        };

        this.designation = {
            type:       'text',
            key:        'designation',
            label:      'form.field.designation',
            size:       6,
            attributes: {
                maxlength: 255,
                readonly:  readonly,
            },
        };

        this.start_date = {
            type:            'material-ui-date-picker',
            key:             'start_date',
            label:           'form.field.start-date',
            size:            6,
            required:        false,
            valueConverters: [
                { name: 'dateFormat' },
            ],
            attributes:      {
                disabled: readonly,
            },
            observers:       [
                async (newValue, oldValue) => {
                    if (newValue) {
                        if (viewModel.model.lot_intervention_type_id === LotInterventionType.INTERVENTION) {
                            const interventionTypeId = viewModel.model.lot_intervention_type_id || viewModel.model.parent_id;

                            if (interventionTypeId) {
                                viewModel.model.number = await this.interventionTypesRepository.nextInterventionNumber(interventionTypeId, viewModel.model);
                            }
                        }

                        viewModel.warning = null;
                    }
                },
            ],
        };

        this.finish_date = {
            type:            'material-ui-date-picker',
            key:             'finish_date',
            label:           'form.field.finish-date',
            size:            6,
            required:        false,
            valueConverters: [
                { name: 'dateFormat' },
            ],
            attributes:      {
                disabled: readonly,
            },
        };

        const schema = [
            [this.lot_intervention_type_id, this.number],
            [this.code, this.designation],
            [this.geosig_phase_id],
            [this.start_date, this.finish_date],
        ];

        if (readonly) {
            this.created_by = {
                type:       'text',
                key:        'created_by',
                label:      'form.field.created-by',
                size:       6,
                required:   false,
                attributes: {
                    readonly: true,
                },
            };

            this.created_at = {
                type:       'text',
                key:        'created_at',
                label:      'form.field.created-at',
                size:       6,
                required:   false,
                attributes: {
                    readonly: true,
                },
            };

            return [...schema, [this.created_by, this.created_at]];
        }

        return schema;
    }

    /**
     * Returns buttons schema
     *
     * @param viewModel
     * @param readonly
     *
     * @returns {*[]}
     */
    globalSchema(viewModel, readonly = false) {
        this.backButton = {
            type:       'button',
            label:      'form.button.back',
            action:     () => viewModel.redirectToRoute('management.concessions.concessions.index'),
            attributes: {
                class: 'btn btn-light',
            },
            icon:       {
                attributes: {
                    class: 'icon-chevron-left',
                },
            },
        };

        this.clearButton = {
            type:       'button',
            label:      viewModel.create ? 'form.button.clear' : 'form.button.reverse-changes',
            action:     () => viewModel.resetForm(),
            hidden:     readonly,
            attributes: {
                class: 'btn btn-light',
            },
            icon:       {
                attributes: {
                    class: 'icon-close2',
                },
            },
        };

        this.submitButton = {
            type:       'submit',
            label:      'form.button.save',
            action:     () => viewModel.submit(),
            hidden:     readonly,
            attributes: {
                class: 'btn btn-primary',
            },
            icon:       {
                attributes: {
                    class: 'icon-floppy-disk',
                },
            },
        };

        this.closeButton = {
            type:       'submit',
            label:      'form.field.close-lot',
            action:     () => this.closeOrReopenLot(viewModel),
            hidden:     viewModel.create || readonly || viewModel.model.closed_id === BooleanOption.YES,
            attributes: {
                class: 'btn btn-danger',
            },
            icon:       {
                attributes: {
                    class: 'icon-lock4',
                },
            },
        };

        this.reopenButton = {
            type:       'submit',
            label:      'form.field.reopen-lot',
            action:     () => this.closeOrReopenLot(viewModel, true),
            hidden:     viewModel.create || readonly || viewModel.model.closed_id === BooleanOption.NO,
            attributes: {
                class: 'btn btn-warning',
            },
            icon:       {
                attributes: {
                    class: 'icon-unlocked',
                },
            },
        };

        this.buttons = {
            type:    'buttons',
            actions: [
                this.backButton,
                this.clearButton,
                this.submitButton,
                this.closeButton,
                this.reopenButton,
            ],
        };

        return [
            [this.buttons],
        ];
    }

    /**
     * Handles lot intervention type change
     *
     * @param newValue
     * @param viewModel
     * @return {Promise<void>}
     */
    async handleLotInterventionTypeChange(newValue, viewModel) {
        if (newValue) {
            if (Number(newValue) === LotInterventionType.INTERVENTION) {
                if (viewModel.model.start_date) {
                    const interventionTypeId = viewModel.model.intervention_type_id || viewModel.model.parent_id;
                    if (interventionTypeId) {
                        viewModel.model.number = await this.interventionTypesRepository.nextInterventionNumber(interventionTypeId);
                    }
                } else {
                    viewModel.warning = viewModel.warningAlertMessage(this.i18N.tr('form.field.please-inser-start-date'));
                }
            } else {
                viewModel.warning      = null;
                viewModel.model.number = null;
            }
        }

        this.number.instance.readonly(Number(newValue) === LotInterventionType.INTERVENTION && viewModel.model.start_date);
    }

    /**
     * Opens close or reopen lot dialog
     *
     * @param viewModel
     * @param reopen
     */
    closeOrReopenLot(viewModel, reopen = false) {
        this.dialogService.open({
            viewModel: reopen ? ConfirmReopenLotDialog : ConfirmCloseLotDialog,
            model:     {},
        }).whenClosed((response) => {
            if (!response.wasCancelled) {
                viewModel.closeOrReopenLot(reopen);
            }
        });
    }

}
